<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
    <ayl-main title="车辆信息" :isCollapse="false">
      <div slot="main" class="p24px">
    <div class="contents">
      <span>车牌号码:</span>
      <span> {{ data.plateNumber ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆归属:</span>
      <span> {{ data.carBelong ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆类型:</span>
      <span> {{ data.carType ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆状态:</span>
      <span> {{ data.carStatus ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>是否启用油量监测:</span>
      <span> {{ data.hasFuel ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>设备号:</span>
      <span> {{ data.deviceName ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>所属车队:</span>
      <span> {{ data.teamName ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>品牌型号:</span>
      <span> {{ data.carBrand ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆自重:</span>
      <span> {{ data.carSelfWeight ||'- -' }}</span>
    </div>
    <div class="contents">
      <span>车辆载重:</span>
      <span> {{ data.calibrationTon ||'- -' }}</span>
    </div>
    </div>
    </ayl-main>
    <ayl-main title="实时油量信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="contents">
          <span>当时里程:</span>
          <span> {{ data.mileage ||'- -' }}</span>
        </div>
        <div class="contents">
          <span>当时位置:</span>
          <span> {{ data.position ||'- -' }}</span>
        </div>
        <div class="contents">
          <span>当时油量:</span>
          <span> {{ data.realTimeOil ||'- -' }}</span>
        </div>
      </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="$router.push('/oil-real/oil-real')">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "油耗管理", path: ""},
          {name: "实时油量", path: "/oil-real/oil-real"},
          {name: "查看详情", path: ""},
        ],
		data:{},
        form: {
          name: null,
          textarea: null
        },
        rules: {
        },
        submitLoading: false
      }
    },
    methods:{

    },
    async mounted () {
      console.log(this.$route.query.deviceId)
      this.data = await this.$api.getRealTimeOilDetail({
        plateNumber: this.$route.query.plateNumber,
        deviceId: this.$route.query.deviceId,
      })
    }
  }
</script>
<style lang='sass' scoped>
</style>
